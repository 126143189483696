import styled, { css } from 'styled-components';

import LogoComponent from 'components/Logo';
import SocialMediasComponent from 'components/SocialMedias';
import { Icon } from 'components/SocialMedias/SocialMedias.styles';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 3vh 3rem;
  position: relative;
  z-index: 10;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
`;

export const DataTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;

export const DataColumn = styled.div`
  padding: 2vh 0;

  ${mediaTablet(css`
    margin-right: 5vw;
  `)}
`;
export const DataRow = styled.div`
  ${mediaTablet(css`
    display: flex;
  `)}
`;
export const Data = styled.div`
  font-size: 1.6rem;
`;

export const HighlightedLink = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${colors.gold};
`;

export const Logo = styled(LogoComponent)`
  width: 20rem;
  height: auto;

  ${mediaTablet(css`
    width: 30rem;
  `)}
`;

export const SocialMedias = styled(SocialMediasComponent)`
  ${Icon} {
    height: 3rem;
  }
`;
