import isValidProp from '@emotion/is-prop-valid';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Menu from 'components/Menu';
import Layout from 'containers/Layout';
import { setupStoreDevTools, useCopyStore, useGlobalStore } from 'store';
import Head from 'u9/components/Head/Head';
// import LanguageSelector from 'u9/components/LanguageSelector/LanguageSelector';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
import gtm from 'u9/utils/gtm';
import { ROUTES } from 'utils/routes';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);

  const pathname = useRouter();

  useEffect(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();

    gtm.initialize(true, true);

    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gtm.trackPage();
  }, [pathname]);

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: router.locale }}>
        <MotionConfig isValidProp={isValidProp}>
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NWXPQJT');`,
            }}
          ></Script>
          <Head {...pageProps.head} />
          {/* <LanguageSelector /> */}
          {/* <NonFunctionals
            // If the non-functionals are the page component, pass the initial copy
            // fetched from the server instead of the stored copy
            initialCopy={pageProps.initialCopy}
            router={router}
          > */}
          <Layout {...pageProps}>
            <AnimatePresence exitBeforeEnter={true} initial={false}>
              <Component key={router.route} router={router} {...pageProps} />
            </AnimatePresence>
          </Layout>
          {/* </NonFunctionals> */}

          {process.env.IS_DEBUG && <Version />}
          {isMounted
            ? window.location.hash === '#version' && <VersionScreen />
            : null}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
