import styled, { css } from 'styled-components';

import CtaButton from 'components/CtaButton';
import LogoComponent from 'components/Logo';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  ${setTypography('paragraph')}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1.3rem;

  background-color: ${colors.black};
  color: white;

  height: 7vmax;
  ${mediaDesktop(css`
    height: 8vh;
  `)}
`;

export const Bar = styled.div`
  height: 7vmax;
  ${mediaDesktop(css`
    height: 8vh;
  `)}
`;

export const Logo = styled(LogoComponent)`
  width: 21.4rem;
  height: 4.5rem;

  ${mediaDesktop(css`
    width: 38.1rem;
    height: 7.9rem;
  `)}
`;

export const MenuItems = styled.div`
  display: none;
  cursor: pointer;

  ${mediaDesktop(css`
    display: flex;
    align-items: center;
  `)}
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  margin-right: 2.5vw;

  &:hover {
    color: ${colors.gold};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
      color: ${colors.gold};
    `}
`;

export const Button = styled(CtaButton)`
  font-size: 2.2rem;
`;
