import { boolean } from '@storybook/addon-knobs';
import styled, { css } from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 0.45rem;
  background-color: ${colors.gold};
  transition: all 150ms ease-in;
  top: 50%;

  transform: translateY(-50%);

  &:first-child {
    top: 0;
    transform: translateY(0) scale(1);
  }

  &:last-child {
    top: auto;
    bottom: 0;
    transform: translateY(0) scale(1);
  }
`;

interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 3.9rem;
  height: 2.85rem;

  display: flex;
  flex-direction: column;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Bar} {
        &:nth-child(2) {
          transform: translateY(-50%) rotate(45deg);
        }
        &:nth-child(3) {
          transform: translateY(-50%) rotate(-45deg);
        }
        &:first-child {
          transform: translateY(200%) scale(0);
        }

        &:last-child {
          transform: translateY(-200%) scale(0);
        }
      }
    `}

  ${mediaDesktop(css`
    display: none;
  `)}
`;
