import React from 'react';

interface FacebookProps {
  className?: string;
}

export default function Facebook({ className }: FacebookProps) {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 21 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7.18309H16.2387H15.2398C14.3799 7.18309 13.6827 7.88023 13.6827 8.74021V14.2033H20L19.0748 20.2176H13.6827V31.25H6.7357V20.2176H1V14.2033H6.6616L6.7357 8.49456L6.72518 7.45811C6.69042 4.01838 9.45065 1.20176 12.8904 1.16697C12.9113 1.16676 12.9323 1.16666 12.9533 1.16666H20V7.18309Z"
        stroke="#D0B63B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
