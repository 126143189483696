import React from 'react';

import * as Styled from './Footer.styles';

export interface FooterProps {
  className?: string;
  socialMedias: SocialMedia[];
  data: FooterData;
}

const defaultProps: Partial<FooterProps> = {};

const Footer: React.FC<FooterProps> = ({
  className,
  socialMedias,
  data,
}: FooterProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Head>
        <Styled.Logo />
        <Styled.SocialMedias socialMediaList={socialMedias} />
      </Styled.Head>
      <Styled.DataRow>
        <Styled.DataColumn>
          <Styled.DataTitle>{data.companyTitle}</Styled.DataTitle>
          <Styled.Data dangerouslySetInnerHTML={{ __html: data.companyData }} />
        </Styled.DataColumn>
        <Styled.DataColumn>
          <Styled.DataTitle>{data.deliveryTitle}</Styled.DataTitle>
          <Styled.Data
            dangerouslySetInnerHTML={{ __html: data.deliveryData }}
          />
        </Styled.DataColumn>
      </Styled.DataRow>
      <Styled.HighlightedLink>Regulamin</Styled.HighlightedLink>
    </Styled.Wrapper>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
