import React from 'react';

import gtm from 'u9/utils/gtm';

import * as Styled from './CtaButton.styles';

export interface CtaButtonProps {
  className?: string;
  children?: any;
  onClick: () => void;
  reverse?: boolean;
}

const defaultProps: Partial<CtaButtonProps> = {
  reverse: false,
};

const CtaButton: React.FC<CtaButtonProps> = ({
  className,
  children,
  onClick,
  reverse,
}: CtaButtonProps) => {
  const handleClick = () => {
    gtm.trackEvent('hiPetsClick');
    onClick();
  };

  return (
    <Styled.Wrapper
      reverse={reverse}
      onClick={handleClick}
      className={className}
      id="CTA-btn"
    >
      {children}
    </Styled.Wrapper>
  );
};

CtaButton.defaultProps = defaultProps;

export default CtaButton;
