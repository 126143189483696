import React from 'react';

import Footer from 'components/Footer';
import Menu from 'components/Menu';
import TopNav from 'components/TopNav';
import { useGlobalStore } from 'store';

import * as Styled from './Layout.styles';

export interface LayoutProps {
  children?: any;
  navItems: NavItemsTypes[];
  socialMedias: SocialMedia[];
  footerData: FooterData;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  navItems,
  socialMedias,
  footerData,
}: LayoutProps) => {
  const { isMenuOpen } = useGlobalStore();

  return (
    <Styled.Wrapper>
      <Menu
        isOpen={isMenuOpen}
        navItems={navItems}
        socialMedias={socialMedias}
      />
      <TopNav navItems={navItems} />

      {children}
      <Footer socialMedias={socialMedias} data={footerData} />
    </Styled.Wrapper>
  );
};

export default Layout;
