import { useRouter } from 'next/router';
import React, { useState } from 'react';

import BurgerMenu from 'components/BurgerMenu';
import { useGlobalStore } from 'store';
import gtm from 'u9/utils/gtm';
import { checkIfPathMatches } from 'utils/utils';

import * as Styled from './TopNav.styles';

export interface TopNavProps {
  navItems: NavItemsTypes[];
}

const defaultProps: Partial<TopNavProps> = {};

const TopNav: React.FC<TopNavProps> = ({ navItems }) => {
  const { isMenuOpen, setIsMenuOpen } = useGlobalStore();
  const { pathname, push } = useRouter();

  return (
    <>
      <Styled.Bar />
      <Styled.Wrapper>
        <Styled.Logo />
        <BurgerMenu
          isOpen={isMenuOpen}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        />
        <Styled.MenuItems>
          {navItems.map(item => (
            <Styled.MenuItem
              isActive={checkIfPathMatches(pathname, item.destination)}
              onClick={() => {
                push(item.destination);
              }}
              key={`menuItem-desktop-${item.name}`}
            >
              {item.name}
            </Styled.MenuItem>
          ))}
          <Styled.Button
            className="top_nav_button"
            onClick={() => {
              gtm.trackEvent('hiPetsClick');
              window.open('https://www.hipets.com/pl-PL/pieskiszyk', '_blank');
            }}
          >
            Zapisy online
          </Styled.Button>
        </Styled.MenuItems>
      </Styled.Wrapper>
    </>
  );
};

TopNav.defaultProps = defaultProps;

export default TopNav;
