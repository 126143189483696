import { useRouter } from 'next/router';
import React from 'react';

import * as Styled from './Logo.styles';

export interface LogoProps {
  className?: string;
}

const defaultProps: Partial<LogoProps> = {};

const Logo: React.FC<LogoProps> = ({ className }: LogoProps) => {
  const { push } = useRouter();

  return <Styled.Logo onClick={() => push('/')} className={className} />;
};

Logo.defaultProps = defaultProps;

export default Logo;
