export const defaultFontSize = 24; // px

export const breakpointTablet = 712; // px
export const breakpointDesktop = 1025; // px
export const breakpointDesktopLarge = 1921; // px

export const desktopMinHeight = 600; // px
export const breakpointMobileSmallHeight = 500; // px

export const mobileBaseWidth = 390; // px
export const mobileBaseHeight = 844; // px
export const tabletBaseWidth = 800; // px
export const desktopBaseWidth = 1728; // px

export const mobileMinFontSize = 11; // px
export const desktopMinFontSize = 12; // px

export const mobileScalableFontSize = 700 / mobileBaseWidth; // vw
export const mobileSmallHeightScalableFontSize = 1000 / mobileBaseHeight; // vw
export const tabletScalableFontSize = 800 / tabletBaseWidth; // vw
export const desktopScalableFontSize = 800 / desktopBaseWidth; // vw
export const desktopWideScalableFontSize = 0.37; // vw

export const desktopWideAspectRatio = '20 / 11';
