import React from 'react';

import SocialIcon from 'components/SocialIcon';

import * as Styled from './SocialMedias.styles';

export interface SocialMediasProps {
  className?: string;
  socialMediaList: SocialMedia[];
}

const defaultProps: Partial<SocialMediasProps> = {};

const SocialMedias: React.FC<SocialMediasProps> = ({
  className,
  socialMediaList,
}: SocialMediasProps) => {
  return (
    <Styled.SocialMedias className={className}>
      {socialMediaList.map(social => (
        <Styled.Icon
          onClick={() => {
            window.open(social.url, '_blank');
          }}
          key={social.platform}
        >
          <SocialIcon platform={social.platform} />
        </Styled.Icon>
      ))}
    </Styled.SocialMedias>
  );
};

SocialMedias.defaultProps = defaultProps;

export default SocialMedias;
