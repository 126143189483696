import styled, { css } from 'styled-components';

import SocialMediasComponent from 'components/SocialMedias';
import { setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 7vmax;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 99;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${colors.black};

  transition: 150ms ease-in;

  opacity: 0;
  transform: translateY(-100%);
  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: all;

      opacity: 1;
      transform: translateY(0);
    `}
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${setVh(40)};
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  margin-right: 2.5vw;
  color: ${colors.white};
  text-align: center;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
      color: ${colors.gold};
    `}
`;

export const SocialMedias = styled(SocialMediasComponent)`
  margin: 15vh 0 5vh 0;
`;
