export enum ColorNames {
  white = 'white',
  black = 'black',
  gold = 'gold',
}

export enum FontNames {
  montserrat = 'montserrat',
}

const validFontCategories = [
  'heading1',
  'heading2',
  'body1',
  'paragraph',
  'button',
] as const;

interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#F5F5F5',
  [ColorNames.black]: '#413F37',
  [ColorNames.gold]: '#D0B63B',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.montserrat]:
      // eslint-disable-next-line quotes
      "'Montserrat', sans-serif",
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    heading1: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { desktop: 4.8, tablet: 4, mobile: 3.6 },
      lineHeight: { desktop: 1.2, tablet: 1.1, mobile: 1.1 },
      fontWeight: 700,
    },
    heading2: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { desktop: 4.8, tablet: 4, mobile: 3.2 },
      lineHeight: { desktop: 1.1, tablet: 1.1, mobile: 1.2 },
      fontWeight: 700,
    },
    body1: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { desktop: 2.5, tablet: 2.2, mobile: 2.4 },
      lineHeight: { desktop: 1.25, tablet: 1.25, mobile: 1.1 },
      fontWeight: 400,
      letterSpacing: 0.03,
    },
    paragraph: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { desktop: 2.4, tablet: 1.6, mobile: 1.6 },
      lineHeight: { desktop: 1.25, tablet: 1.25, mobile: 1.1 },
      fontWeight: 400,
    },
    button: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { desktop: 3.2, tablet: 2.6, mobile: 2.4 },
      lineHeight: { desktop: 1.25, tablet: 1.25, mobile: 1.1 },
      fontWeight: 700,
      letterSpacing: 0.03,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 40, // rem
  lineOpacity: 0.1,
  zIndex: {
    languageSelector: 2,
    nonFunctionals: 3,
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
