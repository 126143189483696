import React from 'react';

import { Facebook, Instagram } from 'components/svgs';

export interface SocialIconProps {
  className?: string;
  platform: string;
}

const defaultProps: Partial<SocialIconProps> = {};

const SocialIcon: React.FC<SocialIconProps> = ({
  className,
  platform,
}: SocialIconProps) => {
  switch (platform) {
    case 'facebook':
      return <Facebook className={className} />;
    case 'instagram':
      return <Instagram className={className} />;
  }
};

SocialIcon.defaultProps = defaultProps;

export default SocialIcon;
