import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.button<{ reverse: boolean }>`
  ${setTypography('button')}
  color: ${colors.white};
  background-color: ${colors.gold};
  border: 1px solid ${colors.gold};

  padding: 1.5rem 2.5rem;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    color: ${colors.gold};
    background-color: ${colors.white};
  }

  ${({ reverse }) =>
    reverse &&
    css`
      color: ${colors.gold};
      background-color: ${colors.white};

      &:hover {
        color: ${colors.white};
        background-color: ${colors.gold};
      }
    `}
`;
