import React from 'react';

import * as Styled from './BurgerMenu.styles';

export interface BurgerMenuProps {
  isOpen: boolean;
  onClick: () => void;
}

const defaultProps: Partial<BurgerMenuProps> = {};

const BurgerMenu: React.FC<BurgerMenuProps> = ({ isOpen, onClick }) => {
  return (
    <Styled.Wrapper isOpen={isOpen} onClick={onClick}>
      <Styled.Bar />
      <Styled.Bar />
      <Styled.Bar />
      <Styled.Bar />
    </Styled.Wrapper>
  );
};

BurgerMenu.defaultProps = defaultProps;

export default BurgerMenu;
