import React from 'react';

interface InstagramProps {
  className?: string;
}

export default function Instagram({ className }: InstagramProps) {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.75 4.75H11.9167C8.4189 4.75 5.58337 7.58553 5.58337 11.0833V26.9167C5.58337 30.4145 8.4189 33.25 11.9167 33.25H27.75C31.2479 33.25 34.0834 30.4145 34.0834 26.9167V11.0833C34.0834 7.58553 31.2479 4.75 27.75 4.75Z"
        stroke="#D0B63B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M19.8333 25.3333C23.3312 25.3333 26.1667 22.4978 26.1667 19C26.1667 15.5022 23.3312 12.6667 19.8333 12.6667C16.3355 12.6667 13.5 15.5022 13.5 19C13.5 22.4978 16.3355 25.3333 19.8333 25.3333Z"
        stroke="#D0B63B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M28.5417 11.875C29.4162 11.875 30.125 11.1662 30.125 10.2917C30.125 9.4172 29.4162 8.70834 28.5417 8.70834C27.6672 8.70834 26.9584 9.4172 26.9584 10.2917C26.9584 11.1662 27.6672 11.875 28.5417 11.875Z"
        fill="#D0B63B"
      />
    </svg>
  );
}
