import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

export const SocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  margin: 0 3vw;
  height: 4rem;
  cursor: pointer;

  ${mediaTablet(css`
    margin: 0 1.5vw;
  `)}

  transition: all 250ms ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;
