import { useRouter } from 'next/router';
import React from 'react';

import CtaButton from 'components/CtaButton';
import { useGlobalStore } from 'store';
import { checkIfPathMatches } from 'utils/utils';

import * as Styled from './Menu.styles';

export interface MenuProps {
  className?: string;
  isOpen: boolean;
  navItems: NavItemsTypes[];
  socialMedias: SocialMedia[];
}

const defaultProps: Partial<MenuProps> = {
  navItems: [],
};

const Menu: React.FC<MenuProps> = ({
  className,
  isOpen,
  navItems,
  socialMedias,
}) => {
  const { pathname, push } = useRouter();
  const { setIsMenuOpen } = useGlobalStore();

  return (
    <Styled.Wrapper isOpen={isOpen} className={className}>
      <Styled.MenuItems>
        {navItems.map(item => (
          <Styled.MenuItem
            onClick={() => {
              push(item.destination);
              setIsMenuOpen(false);
            }}
            isActive={checkIfPathMatches(pathname, item.destination)}
            key={`menuItem-${item.name}`}
          >
            {item.name}
          </Styled.MenuItem>
        ))}
      </Styled.MenuItems>
      <Styled.SocialMedias socialMediaList={socialMedias} />
      <CtaButton
        onClick={() => {
          window.open('https://www.hipets.com/pl-PL/pieskiszyk', '_blank');
        }}
      >
        Umów wizytę
      </CtaButton>
    </Styled.Wrapper>
  );
};

Menu.defaultProps = defaultProps;

export default Menu;
